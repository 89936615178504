import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { manufacturers } from "./lib/Data";
import { Button, Details, Select } from "govuk-react-jsx";
import { useTranslation, Trans } from "react-i18next";

const SelectManufacturer = () => {
  const { t } = useTranslation();
  const [make, setMake] = useState("Abarth");
  const history = useHistory();
  const handleSubmit = (e) => {
    e.preventDefault();
    let path = `/manufacturer/${make}`;
    history.push(path);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='govuk-grid-row'>
          <div className='govuk-grid-column-two-thirds'>
            <h1 className='govuk-heading-l'>{t("selectManufacturer.title")}</h1>

            <Select
              id='manufacturer'
              className='govuk-!-width-full'
              items={manufacturers}
              name='manufacturer'
              onChange={(e) => setMake(e.target.value)}
            />

            <Details
              summaryChildren={t(
                "selectManufacturer.myManufacturerIsNotListed"
              )}
            >
              <p className='govuk-body'>
                {t("selectManufacturer.notAllManufacturers")}
              </p>
              <p className='govuk-body'>
                {t("selectManufacturer.ifYoureNotSure")}
              </p>
              <ul className='govuk-list govuk-list--bullet'>
                <li>{t("selectManufacturer.checkTheVehicleOwnersManual")}</li>
                <li>{t("selectManufacturer.lookInside")}</li>
                <li>{t("selectManufacturer.ask")}</li>
              </ul>
              <p className='govuk-body'>
                {t("selectManufacturer.ifYourStillNotSure")}
              </p>
              <p className='govuk-body'>
                <Trans i18nKey="selectManufacturer.furtherInfo">
                Further information{" "}
                  <a href="https://www.gov.uk/guidance/e10-petrol-explained">E10 petrol explained</a>{" "}
                  page.
                </Trans>
              </p>
            </Details>
          </div>
        </div>

        <br />
        <Button type='submit'>{t('continue')}</Button>
      </form>
    </>
  );
};

export { SelectManufacturer };
