import React from "react";
import { Label, Button, Details } from "govuk-react-jsx";
import { Link, useParams } from "react-router-dom";
import { filteredManufacturer } from "./lib/Data";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";

function Manufacturer() {
  let { manufacturer } = useParams();
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className='govuk-grid-row'>
        <div className='govuk-grid-column-two-thirds'>
          <Label className='govuk-label--l' isPageHeading>
            {manufacturer}
          </Label>

          <div className='govuk-body'>

            <ReactMarkdown
              children={filteredManufacturer(manufacturer)[0][i18n.language]}
            />
          </div>
          <br />
          <Details summaryChildren={t("manufacturer.imStillNotSure")}>
            <p className='govuk-body'>{t("manufacturer.ifYoureNotSure")}</p>
            <ul className='govuk-list govuk-list--bullet'>
              <li>{t("selectManufacturer.checkTheVehicleOwnersManual")}</li>
              <li>{t("selectManufacturer.lookInside")}</li>
              <li>{t("selectManufacturer.ask")}</li>
            </ul>
            <p className='govuk-body'>
              {t("selectManufacturer.ifYourStillNotSure")}
            </p>
          </Details>

          <Button href='https://www.gov.uk/browse/driving'>
            {t("returnToGovUk")}
          </Button>
          <p>
            <Link
              className='govuk-link govuk-link--no-visited-state'
              to='/manufacturer'
            >
              {t("manufacturer.searchAgain")}
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}

export { Manufacturer };
