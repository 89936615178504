import data from "./data.json";

const manufacturers = data.map((x) => {
  return {children: x.Model, value: x.Model };
});

const filteredManufacturer = (query) => {
  return data.filter(function (record) {
    return record.Model === query;
  });
};

export { data, manufacturers, filteredManufacturer };
