import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, WarningText } from "govuk-react-jsx";

const Home = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const handleSubmit = (e) => {
    e.preventDefault();
    let path = `/manufacturer/`;
    history.push(path);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='govuk-grid-row'>
          <div className='govuk-grid-column-two-thirds'>
            <h1 className='govuk-heading-l'>{t("home.title")}</h1>
            <p className='govuk-body'>{t("home.theE10PetrolCheck")} <a className='govuk-link govuk-link--no-visited-state' href="https://www.acea.be/">{t("home.europeanAutomobileManufactures")}</a>{t("home.and")}
            <a className='govuk-link govuk-link--no-visited-state' href="https://www.acem.eu/">{t("home.europeanAssociationofMotorcycleManufacturers")}</a>

    </p>
            <p className='govuk-body'>{t("home.theInformationIsSubject")}</p>
            <p className='govuk-body'>{t("home.DfTDVLAAndItsPartners")}</p>

            <WarningText iconFallbackText='Warning'>
              {t("home.itsYourResponsibility")}
            </WarningText>
          </div>
        </div>

        <br />
        <Button type='submit'>{t("continue")}</Button>
        <br />
      </form>
      <a
        className='govuk-link govuk-link--no-visited-state'
        href='https://www.gov.uk/browse/driving'
      >
        {t("returnToGovUk")}
      </a>
    </>
  );
};

export { Home };
