import React from "react";
import { useTranslation } from "react-i18next";

const Terms = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className='govuk-heading-l'>{t("terms.termsAndConditions")}</h2>
      <p className='govuk-body'>{t("terms.byUsingThis")}</p>
      <p className='govuk-body'>{t("terms.theServiceIs")}</p>
      <h3 className='govuk-heading-m'>{t("terms.accessToTheService")}</h3>
      <p className='govuk-body'>{t("terms.thisServiceProvides")}</p>
      <h3 className='govuk-heading-m'>{t("terms.termination")}</h3>
      <p className='govuk-body'>{t("terms.dftReservethe")}</p>
      <h3 className='govuk-heading-m'>{t("terms.continuityOfService")}</h3>
      <p className='govuk-body'>{t("terms.wherePossible")}</p>
      <h3 className='govuk-heading-m'>{t("terms.disclaimer")}</h3>
      <p className='govuk-body'>{t("terms.dftDoesNot")}</p>
      <ul className='govuk-body'>
        <li>{t("terms.incomeOrRevenue")}</li>
        <li>{t("terms.business")}</li>
        <li>{t("terms.profitsOrContracts")}</li>
        <li>{t("terms.anticipatedSavings")}</li>
        <li>{t("terms.data")}</li>
        <li>{t("terms.goodwill")}</li>
        <li>{t("terms.tangibleProperty")}</li>
        <li>{t("terms.wastedManagementOr")}</li>
      </ul>
      <p className='govuk-body'>{t("terms.websitesOrWeb")}</p>
      <p className='govuk-body'>{t("terms.dftDoesNotControl")}</p>
      <p className='govuk-body'>{t("terms.dftDoesNotAccept")}</p>
      <p className='govuk-body'>{t("terms.theE10Petrol")}</p>
      <p className='govuk-body'>{t("terms.ifInDoubt")}</p>
      <p className='govuk-body'>{t("terms.theDfTDVLA")}</p>
      <p className='govuk-body'>{t("terms.otherPetrolPowered")}</p>
      <p className='govuk-body'>{t("terms.toUnderstandThe")}</p>
      <h3 className='govuk-heading-m'>{t("terms.cookiesAndPrivacy")}</h3>
      <p className='govuk-body'>{t("terms.weCollectInformation")}</p>
      <h3 className='govuk-heading-m'>{t("terms.virusProtection")}</h3>
      <p className='govuk-body'>{t("terms.dftWillMake")}</p>
      <p className='govuk-body'>{t("terms.dftDoesNotAcceptResponsibility")}</p>
      <h3 className='govuk-heading-m'>{t("terms.virusesHackingAnd")}</h3>
      <p className='govuk-body'>{t("terms.ifYouThe")}</p>
      <h3 className='govuk-heading-m'>{t("terms.copyrightAndReproduction")}</h3>
      <p className='govuk-body'>{t("terms.theMaterialFeatured")}</p>
      <p className='govuk-body'>{t("terms.youTheCustomer")}</p>
      <p className='govuk-body'>{t("terms.theNamesImages")}</p>
      <h3 className='govuk-heading-m'>{t("terms.changes")}</h3>
      <p className='govuk-body'>{t("terms.dftReservesThe")}</p>
      <h3 className='govuk-heading-m'>{t("terms.severability")}</h3>
      <p className='govuk-body'>{t("terms.ifAnyOf")}</p>
      <p className='govuk-body'>{t("terms.ifDfTWaives")}</p>
      <h3 className='govuk-heading-m'>{t("terms.eventsBeyondDVLA")}</h3>
      <p className='govuk-body'>{t("terms.DfTandDVLA")}</p>
      <h3 className='govuk-heading-m'>{t("terms.governingLaw")}</h3>
      <p className='govuk-body'>{t("terms.theseTermsAnd")}</p>
    </>
  );
};

export { Terms };
