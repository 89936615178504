import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Template, BackLink } from "govuk-react-jsx";
import { Route, useHistory, Switch } from "react-router-dom";
import { Home } from "./Home";
import { SelectManufacturer } from "./SelectManufacturer";
import { Manufacturer } from "./Manufacturer";
import { Terms } from "./Terms";

import { PhaseBanner } from "govuk-react-jsx";

function App() {
  const { t, i18n } = useTranslation();
  let history = useHistory();

  const changeLanguage = () => {
    // toggle between the languages
    const code = i18n.language === "en" ? "cy" : "en";
    i18n.changeLanguage(code);
  };
  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <Template
      header={{
        serviceName: t("servicename"),
        serviceUrlTo: "/",
        homepageUrlHref: "https://www.gov.uk",
      }}
      footer={{
        meta: {
          items: [
            {
              children: [t("footer.accessibility")],
              href: "https://www.gov.uk/help/accessibility-statement",
            },
            // {
            //   children: [t("footer.cookies")],
            //   href: "https://www.gov.uk/help/cookies",
            // },
            {
              children: [t("footer.termsAndConditions")],
              href: "/terms-conditions",
            },
            {
              children: [
                <div
                  id='language'
                  key='language'
                  onClick={(e) => {
                    e.preventDefault();
                    changeLanguage();
                  }}
                >
                  {t("footer.changeLangage")}
                </div>,
              ],
              className: "govuk-footer__meta-custom",
            },
          ],
        },
      }}
      beforeContent={
        <>
          <PhaseBanner
            tag={{
              children: ["beta"],
            }}
          >
            This is a new service - your{" "}
            <a
              className='govuk-link'
              href='https://www.gov.uk/done/check-vehicle-e10-petrol'
            >
              feedback
            </a>{" "}
            will help us to improve it.
          </PhaseBanner>
          <BackLink onClick={(e) => goBack(e)}>Back</BackLink>
        </>
      }
    >
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        <Route path='/manufacturer/:manufacturer'>
          <Manufacturer />
        </Route>
        <Route path='/manufacturer/'>
          <SelectManufacturer />
        </Route>
        <Route path='/terms-conditions/'>
          <Terms />
        </Route>
        <Route exact path='/'>
          <Home />
        </Route>
      </Switch>
    </Template>
  );
}

export default withRouter(App);
